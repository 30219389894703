
export default {
  primary: "#005e9d",
  secondary: "#083D77",
  black: "#000",
  white: "#fff",
  medium: "#6e6969",
  light: "#D6D6D6",
  dark: "#0c0c0c",
  skin:"#cfdde6",
  danger: "#ff5252",
  blue:"lightblue",
  transparent:'transparent'
};
